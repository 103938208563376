<template>
  <div class="">
    <div class="rounded-xl shadow-xl bg-white p-6 flex">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">系统设置</h1>
        <p class="mt-2 text-sm text-gray-700">
          系统预约时间段设置与恢复默认， 设置全天可预约时间段
        </p>
      </div>
    </div>
    <div
      v-loading="loading"
      class="rounded-xl shadow-xl bg-white p-6 mt-4"
      style="min-height: 500px"
    >
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="工作时间" name="wordDate">
          <div
            class="border border-1 border-gray-200 w-1/2 border-radius-xl my-3"
          >
            <el-row class="border border-1 border-gray-200">
              <el-col :span="8" class="text-center py-2">开始时间（时）</el-col>
              <el-col :span="2" class="text-center py-2"></el-col>
              <el-col :span="8" class="text-center py-2">结束时间（时）</el-col>
              <el-col :span="6"></el-col>
            </el-row>
            <el-row
              class="border border-1 border-gray-200"
              v-for="(item, index) in timePeriod"
              :key="index"
            >
              <el-col :span="8" class="text-center py-2">
                <el-time-select
                  :clearable="false"
                  style="width: 120px"
                  v-model="item['start']"
                  :picker-options="{
                    start: timePeriod[index - 1]
                      ? timePeriod[index - 1]['end']
                      : '00:00',
                    step: '01:00',
                    end: '23:00',
                  }"
                  placeholder="选择时间"
                >
                </el-time-select>
              </el-col>
              <el-col :span="2" class="text-center py-2">-</el-col>
              <el-col :span="8" class="text-center py-2">
                <el-time-select
                  :clearable="false"
                  style="width: 120px"
                  v-model="item['end']"
                  :picker-options="{
                    start: item['start'] || '00:00',
                    step: '01:00',
                    end: '23:00',
                  }"
                  placeholder="选择时间"
                >
                </el-time-select>
              </el-col>
              <el-col :span="6" class="text-center py-2">
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  circle
                  @click="handleCreateTime(index)"
                ></el-button>
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  @click="handleDeleteTime(index)"
                ></el-button>
              </el-col>
            </el-row>
          </div>
          <div>
            <el-button type="primary" @click="handleSaveSetting">
              保存设置
            </el-button>
            <el-button @click="handleReset"> 恢复默认 </el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="关于我们" name="about">
          <i-editor v-model="about"></i-editor>
          <el-button type="primary" @click="handleEditor('about')">
            保存设置
          </el-button>
        </el-tab-pane>
        <el-tab-pane label="个人名片" name="me">
          <div>
            <img :src="me" class="radius" @click="handlePreview"/>
          </div>
          <el-upload
            class="upload-demo"
            ref="upload"
            action=""
            :limit="1"
            :multiple="false"
            :show-file-list="false"
            :http-request="doUpload"
          >
            <el-button slot="trigger" size="small">
              上传
            </el-button>
          </el-upload>
          <el-button type="primary mt-2" @click="handleEditor('me')">
            保存设置
          </el-button>
        </el-tab-pane>
        <el-tab-pane label="用户协议" name="agreement">
          <i-editor v-model="agreement"></i-editor>
          <el-button type="primary" @click="handleEditor('agreement')">
            保存设置
          </el-button>
        </el-tab-pane>
      </el-tabs>
      <div class="text--black text-base"></div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="me" alt="" />
    </el-dialog>
  </div>
</template>
<script type="text/javascript">
import IEditor from "./editor.vue";
export default {
  /**
   * 调用组建
   * @type {Object}
   */
  components: { IEditor },
  /**
   * 组建属性
   * @type {Object}
   */
  props: {},
  /**
   * 数据字段
   * @return {[type]} [description]
   */
  data() {
    return {
      activeName: "wordDate",
      loading: false,
      id: "",
      timePeriod: [
        {
          start: "",
          end: "",
        },
      ],
      about: "", //关于我们
      me: "", // 个人名片
      agreement: "", //用户协议
      dialogVisible: false,
    };
  },
  mounted() {
    this.getTimeList();
  },
  /**
   * 页面调用函数
   * @type {Object}
   */
  methods: {
    /**
     *  @actions:  获取系统设置可预约时间段
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/6/29 23:39
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    getTimeList() {
      this.loading = true;
      this.$cloud
        .post("setting/list", {
          pageIndex: 1,
          pageSize: 100,
        })
        .then((res) => {
          this.loading = false;
          //关于我们
          this.about = res.list.find((item) => item.key === "about").value;
          //名片
          this.me = res.list.find((item) => item.key === "me").value;
          //用户协议
          this.agreement = res.list.find(
            (item) => item.key === "agreement"
          ).value;

          // 设置工作时间
          let wordDate = res.list.find((item) => item.key === "workDate");
          console.log("wordDate", wordDate);
          this.id = wordDate.id;
          let times = [{ start: "", end: "" }];
          try {
            if (wordDate.value) {
              times = JSON.parse(wordDate.value);
            }
          } catch (error) {
            console.log(error);
          }
          this.timePeriod = times.map((item) => {
            Object.keys(item).forEach((key) => {
              item[key] =
                Number(item[key]) < 10 ? `0${item[key]}:00` : `${item[key]}:00`;
            });
            return item;
          });
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },

    /**
     *  @actions:  添加时间段
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/6/29 23:30
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleCreateTime(index) {
      this.timePeriod.splice(index, 0, {
        start: "",
        end: "",
      });
    },

    /**
     *  @actions:  删除时间段
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/6/29 23:32
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleDeleteTime(index) {
      if (this.timePeriod.length > 1) {
        this.timePeriod.splice(index, 1);
      }
    },

    /**
     *  @actions:  保存设置
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/6/30 0:08
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleSaveSetting() {
      this.$confirm("是否保存设置?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let times = this.timePeriod.map((item) => {
          Object.keys(item).forEach((key) => {
            let num = Number(item[key].split(":")[0]);
            return (item[key] = num);
          });
          return item;
        });

        this.loading = true;
        this.$cloud
          .post("setting/update", { id: this.id, value: JSON.stringify(times) })
          .then((res) => {
            this.loading = false;
            if (res) {
              this.$message.success("保存成功");
              this.getTimeList();
            } else {
              this.$message.error(res);
            }
          })
          .catch((err) => {
            this.loading = false;
            this.$message.error(err);
          });
      });
    },

    /**
     *  @actions:  重置设置
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/6/30 0:09
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleReset() {
      this.$confirm("是否恢复默认?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.loading;
        this.$cloud
          .post("setting/set_default", {})
          .then((res) => {
            this.loading = false;
            if (res) {
              this.$message.success("恢复默认成功");
              this.getTimeList();
            } else {
              this.$message.error(res);
            }
          })
          .catch((err) => {
            this.loading = false;
            this.$message.error(err);
          });
      });
    },

    /**
     *  @actions:  保存富文本内容
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/7/12 22:43
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleEditor(key) {
      this.$confirm("是否保存内容并同步更新至小程序?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.loading = true;
        this.$cloud
          .post("setting/set", {
            key,
            value: this[key],
          })
          .then((res) => {
            this.loading = false;
            if (res) {
              this.$message.success("保存同步成功！");
            } else {
              this.$message.error(res);
            }
          })
          .catch((err) => {
            this.loading = false;
            this.$message.error(err);
          });
      });
    },

    handlePreview() {
      console.log("查看预览", this.me);
      if (this.me) {
        this.dialogVisible = true;
      }
    },
    handleUpload() {
      this.$refs.upload.submit();
      console.log("重新上传");
    },
    doUpload(e) {
      console.log("e", e);
      this.$message.info("上传中");
      let params = {
        file: e.file,
      };
      this.$cloud
        .upload("files/upload", params)
        .then((res) => {
          console.log("res", res);
          this.$message.success("上传完成");
          this.me = res.url;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
  },
};
</script>
<style lang="scss"></style>
