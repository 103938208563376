<!--
 * ======================================
 * 说明： 富文本编辑组件
 * 作者： Silence
 * 文件： editor.vue
 * 日期： 2023/7/12 22:22
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div style="border: 1px solid #ccc; margin-bottom: 10px">
    <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :mode="editorMode"
    />
    <Editor
      :style="`overflow-y: hidden; height:${height}`"
      v-model="content"
      :defaultConfig="editorConfig"
      :mode="editorMode"
      @onCreated="onCreated"
    />
  </div>
</template>
<script>
import "@wangeditor/editor/dist/css/style.css";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
export default {
  name: "",
  components: { Editor, Toolbar },
  props: {
    value: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "565px",
    },
  },
  data() {
    return {
      // 富文本
      editor: null,
      toolbarConfig: {
        excludeKeys: ["group-video", "codeBlock", "fullScreen"],
      },
      editorConfig: {
        placeholder: "请输入内容...",
        MENU_CONF: {
          // 所有的菜单配置，都要在 MENU_CONF 属性下
          uploadImage: {
            customUpload: async (file, insertFn) => {
              this.onUploadPicture({ file }, insertFn);
            },
          },
          uploadVideo: {},
        },
      },
      editorMode: "default", // or 'simple'
    };
  },
  computed: {
    content: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  created() {},
  mounted() {},
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错

      console.log("keys:", this.editor.getAllMenuKeys());
    },

    /**
     *  @actions:  图片上传
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/7/5 22:03
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    onUploadPicture(file, insertFn) {
      let params = {
        file: file.file,
      };
      console.log("params:", params);
      this.loading = true;
      this.$cloud
        .upload("files/upload", params)
        .then((res) => {
          this.loading = false;
          if (insertFn) {
            let origin = document.location.origin;
            insertFn(origin + res.url);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
